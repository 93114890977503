import { BehaviorSubject, fromEvent, Subscription, forkJoin } from "rxjs";
import { map, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ApiService } from "app/services/api.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { CameraNamePipe, HighlightSearch } from "app/pipes/pipes";
import { trigger, transition, style, animate } from "@angular/animations";
import { CameraGroupEditComponent } from "./camera-group-edit/camera-group-edit.component";
import { CameraGroupAddComponent } from "./camera-group-add/camera-group-add.component";
import { Device } from "app/models/device.model";
import { MatExpansionPanel, MatExpansionModule } from "@angular/material/expansion";
import { IMultiSelectOption } from "ngx-bootstrap-multiselect";
import { AccessMode, ICameraGroup, IVisitConfiguration } from "app/models/camera.model";
import { FormsModule } from "@angular/forms";
import { IGroupedOption, IOption } from "../multiselect-dropdown/multiselect-dropdown.component";
import {
  AccessModeToLangugeCodePipe,
  CameraGroupFullAddressPipe,
  DeviceNamePipe,
  LocationNamePipe,
  ReceiverTeamNamePipe,
} from "./camera-configuration-pipes";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";


interface ICameraGroupDataFromAPI {
  cameraGroups: ICameraGroup[];
  visitConfigs: IVisitConfiguration[];
}

interface ICameraGroupData {
  cameraGroup: ICameraGroup;
  visitConfig: undefined | IVisitConfiguration;
  panelExpanded?: boolean;
}

interface IReceiverTeam {
  groupId: string;
  groupName: string;
  locationId: string;
  customerId: string;
  customerName: string;
}

@Component({
  selector: "camera-configuration",
  templateUrl: "./camera-configuration.component.html",
  styleUrls: ["./camera-configuration.component.css"],
  animations: [
    trigger("fadeOut", [
      transition(":leave", [animate("0.5s ease-out", style({ opacity: 0 }))]),
    ]),
  ],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    MatExpansionModule,
    HighlightSearch,
    CameraGroupEditComponent,
    CameraGroupAddComponent,
    CameraGroupFullAddressPipe,
    LocationNamePipe,
    DeviceNamePipe,
    ReceiverTeamNamePipe,
    AccessModeToLangugeCodePipe,
    CameraNamePipe,
  ],
})
export class CameraConfigurationComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) { }

  user: any;

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  private _search: string = "";

  cameraGroups: ICameraGroupData[];
  filteredCameraGroups: ICameraGroupData[];

  pageDataLoaded: boolean = false;
  cameraGroupDataToEdit: ICameraGroupData;
  showNewGroupForm = new BehaviorSubject<boolean>(false);
  showEditForm = new BehaviorSubject<boolean>(false);
  successMsg: string = "";
  warningMsg: string = "";
  msgFadeOutTime: number = 5000;

  // Form selection data variables
  alertDeviceSelect: IOption[] = [];
  locationSelect: { id: string; name: string }[] = [];
  receiverTeamSelect: IGroupedOption[] = [];

  /**
   * List of VL-201 cameras
   */
  cameraList: Device[];
  assignedCameraIds: string[] = []; // List of camera ids that are already assigned to a camera group

  subscriptions: Subscription[] = [];

  get search() {
    return this._search;
  }

  set search(text: string) {
    this._search = text;
    if (text.length === 0) {
      this.filteredCameraGroups = this.cameraGroups;
      this.closeAllPanels();
    } else {
      // Filter camera groups by matching the property values to the search text
      this.filteredCameraGroups = this.cameraGroups.filter(
        (item) =>
          (item.cameraGroup.accessMode
            ? this.getAccessModeTranslation(item.cameraGroup.accessMode)
              .toLocaleLowerCase()
              .includes(text)
            : false) ||
          (item.cameraGroup.displayName
            ? item.cameraGroup.displayName.toLocaleLowerCase().includes(text)
            : false) ||
          this.getCameraGroupFullAddress(item.cameraGroup)
            .toLocaleLowerCase()
            .includes(text) ||
          (item.cameraGroup.timeZone
            ? item.cameraGroup.timeZone.toLocaleLowerCase().includes(text)
            : false) ||
          (item.cameraGroup.locationId
            ? this.getLocationName(item.cameraGroup.locationId)
              .toLocaleLowerCase()
              .includes(text)
            : false) ||
          (item.cameraGroup.userGroups
            ? item.cameraGroup.userGroups.some((groupId) =>
              this.getUserGroupName(groupId)
                .toLocaleLowerCase()
                .includes(text)
            )
            : false) ||
          (item.cameraGroup.cameras
            ? item.cameraGroup.cameras.some(
              (camera) =>
                (camera.displayName
                  ? camera.displayName.toLocaleLowerCase().includes(text)
                  : false) ||
                (camera.discoveryName
                  ? camera.discoveryName.toLocaleLowerCase().includes(text)
                  : false)
            )
            : false) ||
          (item.visitConfig?.options.visitTimeMinutes
            ? item.visitConfig?.options.visitTimeMinutes
              .toString()
              .toLocaleLowerCase()
              .includes(text)
            : false) ||
          (item.visitConfig?.options.visitGraceTimeMinutes
            ? item.visitConfig?.options.visitGraceTimeMinutes
              .toString()
              .toLocaleLowerCase()
              .includes(text)
            : false) ||
          (item.visitConfig?.alertSources
            ? item.visitConfig?.alertSources.some((obj) =>
              this.getDeviceName(obj.deviceID)
                .toLocaleLowerCase()
                .includes(text)
            )
            : false)
      );
      this.openAllPanels();
    }
  }

  openAllPanels(): void {
    this.panels.forEach((panel) => panel.open());
  }

  closeAllPanels(): void {
    this.panels.forEach((panel) => panel.close());
  }

  preventCheckboxClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // Set camera group to edit and open edit form
  setEditGroup(cameraGroupData: ICameraGroupData): void {
    this.showEditForm.next(false);
    this.cameraGroupDataToEdit = cameraGroupData;
    this.closeAllPanels();
    this.showEditForm.next(true);
  }

  compareNames(a: any, b: any): number {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }

  parseReceiverTeamsForLocations(
    receiverTeams: IReceiverTeam[],
    locations: IMultiSelectOption[]
  ): IGroupedOption[] {
    const options: IGroupedOption[] = [];
    var customerId: string = "";

    // Get customer id from the receiver team list
    if (receiverTeams?.length > 0) {
      customerId = receiverTeams[0].customerId;
    }

    for (const location of locations) {
      const receiverTeamByLocation: IGroupedOption = {
        label: location.name,
        id: location.id,
        options: receiverTeams
          .filter((team) => (team.locationId ?? team.customerId) === location.id)
          .map((team) => ({
            value: team.groupId,
            name: team.groupName,
          }))
      };
      options.push(receiverTeamByLocation);
    }
    return options.filter((option) => option.options.length > 0);
  }

  parseCameraGroupList(data: ICameraGroupDataFromAPI): ICameraGroupData[] {
    const array: ICameraGroupData[] = [];
    const cameraGroups = data.cameraGroups;
    const visitConfigs = data.visitConfigs;

    for (let group of cameraGroups) {
      array.push({
        cameraGroup: group,
        visitConfig: visitConfigs.find(
          (config) => config.id.substring("alerts-".length) === group.id
        ),
      });
    }
    return array;
  }

  getDeviceName(deviceId: string): string {
    const device = this.alertDeviceSelect.find(
      (option) => option.value === deviceId
    );
    return device?.name || "";
  }

  getUserGroupName(groupId: string): string {
    const receiverTeam: IOption = this.receiverTeamSelect
      .reduce((result, obj) => {
        return result.concat(obj.options);
      }, [])
      .find((team) => team.value === groupId);

    return receiverTeam?.name || "";
  }

  getLocationName(serverIdLocationId: string): string {
    const location = this.locationSelect.find(
      (option) => option.id === serverIdLocationId.substring(8)
    );
    return location?.name || "";
  }

  getCameraGroupFullAddress(cameraGroup: ICameraGroup): string {
    const address = cameraGroup.address;
    const city = cameraGroup.postOffice;
    const postCode = cameraGroup.postCode;

    const parts = [address, postCode, city].filter(Boolean);
    return parts.join(", ");
  }

  getAccessModeTranslation(accessMode: AccessMode): string {
    const langCode =
      accessMode === AccessMode.Always
        ? "ACCESS_MODE_ALWAYS"
        : accessMode === AccessMode.VisitOnAlert
          ? "VISIT_ON_ALERT"
          : "SCHEDULED_VISIT";

    return this.translate.instant(langCode) as string;
  }

  setSuccessMessage(message: string) {
    this.successMsg = message;
    setTimeout(() => {
      this.successMsg = "";
    }, this.msgFadeOutTime);
  }

  setWarningMessage(message: string) {
    this.warningMsg = message;
    setTimeout(() => {
      this.warningMsg = "";
    }, this.msgFadeOutTime);
  }

  refreshCameraGroups(message: string): void {
    this.setSuccessMessage(message);

    this.api.getCameraGroups()
      .pipe(
        tap(() => this.pageDataLoaded = false),
        map((res: {
          cameraGroups: ICameraGroup[];
          visitConfigs: IVisitConfiguration[];
        }) => {
          this.cameraGroups = this.parseCameraGroupList(res);
          this.filteredCameraGroups = this.cameraGroups;
          this.assignedCameraIds = this.getCameraIdsFromGroups(this.filteredCameraGroups);
        }),
        tap(() => this.pageDataLoaded = true)
      )
      .subscribe();
  }

  getPageData(): void {
    const getCameraGroups$ = this.api.getCameraGroups();
    const getDevices$ = this.api.getDevices({ fullInfo: false });
    const getFilterOptions$ = this.api.getReportFilteringOptions();
    const getReceiverTeams$ = this.api.getAllMainUserReceiverTeams();

    this.pageDataLoaded = false;

    const subscription = forkJoin([
      getCameraGroups$,
      getDevices$,
      getFilterOptions$,
      getReceiverTeams$,
    ]).subscribe((result) => {
      if (!result || result.length < 4) return;

      const cameraGroupData: ICameraGroupDataFromAPI = result[0];
      const devices: Device[] = result[1];
      const filterOptions: any = result[2];
      const receiverTeams: IReceiverTeam[] = result[3];

      const alertDevices: IOption[] = filterOptions.devices
        .filter(
          (device: any) => device.id.substring(0, 2) !== "34" // Remove cameras
        ).map((device: any) => ({
          value: device.id,
          name: device.name,
        }))

      // Filter VL-201 cameras
      this.cameraList = devices.filter((device) => device.deviceType == "34-1");

      this.locationSelect = filterOptions.locations.sort(this.compareNames);
      this.alertDeviceSelect = alertDevices.sort(this.compareNames);

      // Add customer as location option
      if (receiverTeams && receiverTeams.length > 0) {
        const customerId = receiverTeams[0].customerId;
        const customerName = receiverTeams[0].customerName;

        this.locationSelect.unshift({
          id: customerId,
          name: `${customerName} (${customerId})`
        });
      }

      this.receiverTeamSelect = this.parseReceiverTeamsForLocations(
        receiverTeams,
        this.locationSelect
      );

      this.cameraGroups = this.parseCameraGroupList(cameraGroupData);
      this.filteredCameraGroups = this.cameraGroups;

      // Retrieve the already assigned camera IDs from the camera groups to prevent reassigning them in the forms
      this.assignedCameraIds = this.getCameraIdsFromGroups(this.filteredCameraGroups);
      this.pageDataLoaded = true;

      this.checkUrlParams();
    });

    // Collect subscription
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  checkUrlParams(): void {
    const cameraGroupId = this.route.snapshot.paramMap.get('id');
    const group = this.filteredCameraGroups.find((item) => item.cameraGroup.id === cameraGroupId);

    if (group) {
      group.panelExpanded = true;
    }
    else if (cameraGroupId) {
      // If camera group is not found, but the id is provided in the URL, then camera group is already deleted
      this.setWarningMessage(this.translate.instant("CAMERA_GROUP_ALREADY_DELETED"));
    }
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));

    this.getPageData();

    // Check typing for the search element
    const subscription = fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        // Get value
        map((event: any) => event.target.value),
        // Time in milliseconds between key events
        debounceTime(50),
        // Query only if the value is changed
        distinctUntilChanged()
      )
      .subscribe((query: string) => (this.search = query.toLocaleLowerCase()));

    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  // Do a custom cleanup
  ngOnDestroy(): void {
    this.searchInput = null;
    this.showNewGroupForm.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }


  /**
 * This function extracts camera ids from the all camera groups.
 * @param {Array} cameraGroups - An array of camera group objects.
 * @returns {Array} An array of ids. If `cameraGroups` is null or undefined, the function returns undefined.
 */
  getCameraIdsFromGroups(cameraGroups: ICameraGroupData[]) {
    if (!cameraGroups) return;

    let discoveryNames = []
    for (let group of cameraGroups) {
      for (let camera of group.cameraGroup.cameras) {
        discoveryNames.push(camera.discoveryName)
      }
    }
    return discoveryNames
  }
}

